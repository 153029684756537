import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environments';
import { Item } from '../api/item';
import { PaginatedResponse, PaginationInfo } from '../api/item-paginator';

@Injectable({
  providedIn: 'root',
})
export class CatalogService {
  baseRoute = environment.genuzApiEndpoint;

  constructor(private httpClient: HttpClient) {}

  getById(itemId: number): Observable<Item> {
    return this.httpClient.get<Item>(`${this.baseRoute}item/${itemId}`);
  }

  getItems(pagination?: PaginationInfo): Observable<PaginatedResponse<Item>> {
    let params = new HttpParams()
      .set('page', pagination?.currentPage.toString() || '0')
      .set('size', pagination?.numberOfRecords.toString() || '10');

    if (pagination?.sortField) {
      const sortField = Array.isArray(pagination.sortField)
        ? pagination.sortField.join(',') // Combina os valores se for um array
        : pagination.sortField; // Caso contrário, usa diretamente

      params = params.set('sortField', sortField);
    }
    if (pagination?.sortOrder)
      params = params.set('sortOrder', pagination.sortOrder?.toString() || '1');

    if (pagination?.filters) {
      Object.keys(pagination?.filters).forEach((key) => {
        params = params.append(`Filters[${key}]`, pagination?.filters[key]);
      });
    }

    return this.httpClient.get<PaginatedResponse<Item>>(
      `${this.baseRoute}item/`,
      { params }
    );
  }

  getItemsFiltered(filters: any): Observable<Item[]> {
    return this.httpClient.get<Item[]>(`${this.baseRoute}item/get-by-filter`, {
      params: filters,
    });
  }

  getCatalogItems(filters: any): Observable<Item[]> {
    return this.httpClient.get<Item[]>(`${this.baseRoute}item/my-catalog`, {
      params: filters,
    });
  }

  getStoreItems(filters: any): Observable<Item[]> {
    return this.httpClient.get<Item[]>(`${this.baseRoute}store/`, {
      params: filters,
    });
  }

  add(item: Item): Observable<Item> {
    return this.httpClient.post<Item>(`${this.baseRoute}item/`, item);
  }

  update(item: Item): Observable<Item> {
    return this.httpClient.put<Item>(`${this.baseRoute}item/`, item);
  }

  publishItem(item: Item): Observable<Item> {
    return this.httpClient.put<Item>(`${this.baseRoute}item/publish`, item);
  }
  unpublishItem(item: Item): Observable<Item> {
    return this.httpClient.put<Item>(`${this.baseRoute}item/unpublish`, item);
  }

  deleteItem(itemToDelete: number[]): Observable<void> {
    return this.httpClient.delete<void>(
      `${this.baseRoute}item?id=${itemToDelete[0]}`
    );
  }
}
