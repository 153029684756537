<p-progressSpinner class="flex" class="spinner" *ngIf="loading" />

<div class="not-found-warning" *ngIf="!loading && !items.length">
  <div class="text-center" >
    <i class="pi pi-exclamation-circle text-3xl" style="color: #ef4444"></i>
    <h5>{{ 'search.404.title' | translate }}</h5>
    <p>{{ 'search.404.tip' | translate }}</p>
  </div>
</div>

<div class="col-12" *ngIf="items.length">
  <div class="navbar mb-3">
    <div>
      <h4>{{ 'search.navbar.results' | translate }} "{{ queryParams.text }}"
        <span class="text-lg ml-4" style="color: grey">
          {{
            total == 1
                ? total + ('search.navbar.counter.singular' | translate)
                : total + ('search.navbar.counter.plural' | translate)
          }}
        </span>
      </h4>
    </div>
    <div>
      <app-filter-button
        [items]="items"
        [isSearchFilter]="true"
        (filteredItems)="updateFilteredItems($event)"></app-filter-button>
    </div>
  </div>

  <div class="card items">
    <div class="card grid gap-2" *ngFor="let item of items">
      <div class="item-image col-fixed border-round-sm p-3 mr-2"
           style="background-image: url({{ item.itemPreviews.length ? getThumbnail(item) : 'assets/no_preview.png' }})"></div>

      <div class="col p-0">
        <div
          *ngIf="item.itemIcon"
          class="item-image-icon col-fixed border-round-sm p-3 mr-3"
          style="background-image: url({{ getIcon(item) }})"></div>

        <h3 [highlight]="queryParams.text" [content]="item.name" class="text-lg font-bold mt-1"></h3>
        <p class="text-md font-light">{{ getTypeLabel(item.itemType?.id) }}</p>

        <div class="flex ratings">
          <span class="p-text-secondary font-medium mr-1">
            {{rating}}
          </span>
          <p-rating [(ngModel)]="rating" [cancel]="false" [readonly]="true" [stars]="1" />
        </div>

        <div class="owner flex align-items-center gap-2 mt-5" *ngIf="item.ownerName">
          <span>By</span>
          <p-chip [label]="item.ownerName" [image]="item.ownerPhoto" alt="Avatar image" />
        </div>

        <p *ngIf="item.description" class="mt-3">
          <span [highlight]="queryParams.text" [content]="item.description"></span>
          <a (click)="toggleDetailsView(item, $event)" href="#">{{ 'search.card.view_more' | translate }}</a>
        </p>
      </div>

      <div class="col-fixed" style="width: 110px">
        <div class="text-right" style="height: 90%">
          @if (hasAccess(item) || isOwner(item)) {
          <button
            pButton
            [disabled]="!item.itemAccessUrl"
            (click)="toItemView(item)"
            color="primary"
            class="p-button"
            size="large"
            rounded="true">
            {{ 'components.item-card.button.open' | translate }}
          </button>
          }
          @else if (item.isAccessPending){
          <button pButton disabled [rounded]="true" class="p-button" color="primary" rounded="true" size="large" target="_blank">
            {{ 'item_details.header.actions.pending' | translate }}
          </button>
          } @else{
          <button pButton [rounded]="true" class="p-button" (click)="toggleRequestForm(item, $event)" color="primary" size="large">
            {{ 'item_details.header.actions.get' | translate }}
          </button>
          }
        </div>

        <div class="counters flex gap-2">
          <p-chip [label]="item.itemTerms?.length?.toString() ?? '0'" icon="pi pi-book" />
          <p-chip [label]="item.itemPermissions.length.toString() || '0'" icon="pi pi-user" />
        </div>
      </div>

    </div>

    <p-paginator
      (onPageChange)="onPageChange($event)"
      [first]="first"
      [rows]="rows"
      [totalRecords]="total"
      [rowsPerPageOptions]="[15, 30, 50]" />
  </div>
</div>

<app-request-access-form
  *ngIf="selectedItem"
  [(isOpen)]="openRequestForm" [isRequest]="true" [item]="selectedItem" [requester]="selectedItem.ownerName"></app-request-access-form>

<app-details
  *ngIf="selectedItem"
  [(isOpen)]="sidebarVisible" [item]="selectedItem"></app-details>
