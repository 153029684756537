import { Injectable } from '@angular/core';
import { User } from '../api/user';

@Injectable({ providedIn: 'root' })
export class UserCache {
  cache: User[] = [];

  constructor() { }

  add(user: User): void {
    if (user && !this.has(user.id))
      this.cache.push(user);
  }

  has(UID: string): boolean {
    return this.cache?.some(user => user.id === UID);
  }

  get(UID: string): User {
    return this.cache.find(user => user.id === UID) as User;
  }

  clear(): void {
    this.cache = [];
  }
}
