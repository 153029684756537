import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserService } from '../../service/user.service';
import { InputTextModule } from 'primeng/inputtext';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { CommonModule } from '@angular/common';
import { User } from '../../api/user';
import { FormsModule, NgModel } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AutoCompleteCompleteEvent, AutoCompleteModule, AutoCompleteSelectEvent } from 'primeng/autocomplete';

@Component({
  selector: 'user-dropdown',
  standalone: true,
  providers: [UserService],
  imports: [
    InputTextModule,
    FormsModule,
    AutoCompleteModule,
    CommonModule,
    ProgressSpinnerModule,
    TranslateModule,
  ],
  templateUrl: './user-dropdown.component.html',
  styleUrls: ['./user-dropdown.component.scss'],
})
export class UserDropdownComponent implements OnInit {
  users: User[] = [];

  @Input() user: User = { name: '' } as User;
  @Input() userName: string = '';
  @Input() userId: string | null = null;
  @Input() placeholder: string = '';
  @Input() required: boolean = false;

  @Output() selectedUser = new EventEmitter<User>();
  @Output() onChange = new EventEmitter<string>();
  @Input() isDisabled = false;

  constructor(private userService: UserService) {}

  ngOnInit(): void {
    if (this.userName)
      this.user.name = this.userName;

    if (this.userId)
      this.searchById(this.userId);
  }

  search(event: AutoCompleteCompleteEvent): void {
    this.userService.getUserAndGroups(event.query)
      .subscribe(users => this.users = users);
  }

  searchById(userId: string): void {
    this.userService.getUserAndGroupsById(userId)
      .subscribe(user => this.user = user);
  }

  selectUser(event: AutoCompleteSelectEvent): void {
    this.user = event.value;
    this.selectedUser.emit(this.user);
  }

  modelChange(event: any): void {
    this.onChange.emit(event);
  }
}
