import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, of, share, shareReplay } from 'rxjs';
import { environment } from '../../environments/environments';
import { User } from '../api/user';
import { MsalService } from '@azure/msal-angular';
import { UserCache } from './user-cache.service';

@Injectable({ providedIn: 'root' })
export class UserService {
  baseRoute = environment.genuzApiEndpoint;

  user$!: Observable<User>;

  constructor(
    private httpClient: HttpClient,
    private authService: MsalService,
    private userCache: UserCache
  ) {}

  getAll(): Observable<User[]> {
    return this.httpClient.get<User[]>(`${this.baseRoute}user`);
  }

  updateLanguagePreference(userId: string, language: string): Observable<User> {
    const userSettings: { UserId: string; Language: string } = {
      UserId: userId,
      Language: language,
    };

    return this.httpClient.post<User>(
      `${this.baseRoute}user/user-information`,
      userSettings
    );
  }

  createNewUser(userId: string): Observable<User> {
    const userSettings: { UserId: string } = {
      UserId: userId,
    };

    return this.httpClient.post<User>(
      `${this.baseRoute}user/new-user`,
      userSettings
    );
  }

  updateNotificationPreference(
    userId: string,
    allowNotification: boolean
  ): Observable<User> {
    const userSettings: { UserId: string; AllowNotifications: boolean } = {
      UserId: userId,
      AllowNotifications: allowNotification,
    };

    return this.httpClient.post<User>(
      `${this.baseRoute}user/user-information`,
      userSettings
    );
  }

  getUserInformation(userId: string): Observable<User> {
    if (!userId || userId === '') {
      console.warn('User Id is not provided; skipping user information fetch');
      return of();
    }

    if (this.userCache.has(userId))
      return of(this.userCache.get(userId));

    if (this.user$)
      return this.user$;

    this.user$ = this.httpClient.get<User>(`${this.baseRoute}user/user-information`, {
      params: { id: userId },
    }).pipe(shareReplay());

    return new Observable<User>(sub => {
      this.user$.subscribe(user => {
        this.userCache.add(user);

        sub.next(user);
        sub.complete();
      });
    });
  }

  getUserAndGroups(name: string): Observable<User[]> {
    return this.httpClient.get<User[]>(
      `${this.baseRoute}user/user-and-groups`,
      { params: { name } }
    );
  }

  getUserAndGroupsById(id: string): Observable<User> {
    return this.httpClient.get<User>(`${this.baseRoute}user/user-or-group`, {
      params: { id },
    });
  }

  getUserId(): string {
    const account = this.authService.instance.getActiveAccount();
    return account?.localAccountId || '';
  }

  getPhoto(): Observable<string> {
    return of('');
    return this.httpClient
      .get(environment.graphEndpoints.photo, { responseType: 'blob', headers: {} })
      .pipe(map((response: any) => URL.createObjectURL(response)));
  }

  getPhotoByUserId(userId: string): Observable<string> {
    return of('');
    return this.httpClient
      .get(`https://graph.microsoft.com/v1.0/users/${userId}/photo/$value`, {
        responseType: 'blob',
        headers: {}
      })
      .pipe(map((response: any) => URL.createObjectURL(response)));
  }
}
