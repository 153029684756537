import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Table, TableModule } from 'primeng/table';
import { Item, ItemTerm } from '../../../api/item';
import {
  Term,
  termsTypesTranslationPaths,
  translateFromArrayAt,
} from '../../../api/term';
import { GlossaryService } from '../../../service/glossary.service';
import { ButtonModule } from 'primeng/button';
import { CommonModule } from '@angular/common';
import { InputTextModule } from 'primeng/inputtext';

@Component({
  selector: 'app-details-relationship-tab',
  standalone: true,
  imports: [
    TableModule,
    TranslateModule,
    ButtonModule,
    CommonModule,
    InputTextModule,
  ],
  templateUrl: './details-relationship-tab.component.html',
  styleUrl: './details-relationship-tab.component.scss',
})
export class DetailsRelationshipTabComponent implements OnChanges {
  @Input() item!: Item;
  @Input() tabIndex = 0;

  itemTerms: Term[] = [];
  isFilterRowVisible: boolean = false;
  hasLoaded: boolean = false;
  relationshipTabIndex: number = 1;

  translatedTemplate: any = {
    page_report: '',
    table_search_field: '',
  };

  constructor(
    private translate: TranslateService,
    private termService: GlossaryService
  ) { }

  ngOnInit() {
    this.translate
      .get('glossary.table.page_report')
      .subscribe((res: string) => {
        this.translatedTemplate.page_report = res;
      });

    this.translate
      .get('glossary.table.search_field')
      .subscribe((res: string) => {
        this.translatedTemplate.table_search_field = res;
      });
  }

  ngOnChanges(changes: SimpleChanges) {

    if (!this.hasLoaded && changes?.tabIndex?.currentValue == this.relationshipTabIndex)
      if (this.item.itemTerms) {
        for (const itemTerm of this.item.itemTerms) {
          this.termService
            .getTermsFiltered({ Id: itemTerm.termId })
            .subscribe((terms: Term[]) => {
              const term: any = terms[0];
              term.typeLabel = this.getTermTypeLabel(term.type);
              this.itemTerms.push(term);

              this.hasLoaded = true;
            });
        }
      }
  }

  getTermTypeLabel(termTypeId: number): string {
    return translateFromArrayAt(
      termsTypesTranslationPaths,
      termTypeId,
      this.translate
    );
  }

  toggleFilter() {
    this.isFilterRowVisible = !this.isFilterRowVisible;
  }

  onGlobalFilter(table: Table, event: Event) {
    table.filterGlobal((event.target as HTMLInputElement).value, 'contains');
  }
}
