<div class="upload-container">
  <p-fileUpload #fileUploader name="images[]" [files]="files" fileLimit="5" [multiple]="true" class="file-uploader" accept="image/*"
                chooseLabel="{{ 'catalog.form.fields.upload.label' | translate }}" (onSelect)="onFileSelected($event, fileUploader)" showUploadButton="false"
                showCancelButton="false" [disabled]="isDisabled">
    <ng-template pTemplate="file" let-file>
      <div class="p-fileupload-files ng-star-inserted">
        <div class="ng-star-inserted">
          <div class="p-fileupload-row ng-star-inserted">
            <div class="ng-star-inserted">
              <img [ngSrc]="renderImage(file)" alt="Selected Image" style="max-width: 100px; max-height: 100px;" width="120" height="80">
            </div>
            <div class="p-fileupload-filename"> {{ file.name }}</div>
            <span> {{ file.size }} bytes</span>
            <div>
              <button
                pButton
                pRipple
                icon="pi pi-check"
                [class]="{ 'thumbnail-set': isThumbnailSet === file.name }"
                (click)="setThumbnail(file, fileUploader)"
                pTooltip="Set as thumbnail"
                [rounded]="true"
                [text]="true"
                class="p-button-rounded p-button-outlined preview-action-button mr-2"
                severity="secondary" [disabled]="isDisabled"
                ></button>
              <button
                pButton
                pRipple
                type="button"
                icon="pi pi-trash"
                class="p-button preview-action-button"
                (click)="deleteFile(file, fileUploader, $event)" [disabled]="isDisabled"
                ></button>
            </div>
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template pTemplate="empty">
      <div class="flex align-items-center justify-content-center flex-column">
        <i class="pi pi-cloud-upload border-2 border-circle p-5 text-8xl text-400 border-400"></i>
        <p class="mt-4 mb-0">{{ 'catalog.form.fields.upload.description' | translate }}</p>
        <p class="mt-4 mb-0">{{ 'catalog.form.fields.upload.or' | translate }}</p>
      </div>
    </ng-template>
  </p-fileUpload>
</div>
