<a (click)="cardClickHandler($event, isMyCatalogView)">
  <p-card [header]="getTypeLabel(item.itemType?.id)">
    <ng-template pTemplate="header">
      <div class="item-card-img">
        <img class="item-card-img" [ngSrc]="urlImg" fill>
      </div>
    </ng-template>
    <ng-template pTemplate="subheader">
      <b class="item-name" [pTooltip]="(item.name?.length ?? 0) >= 45 ? item.name : undefined" tooltipPosition="bottom">{{ item.name | truncate:45 }}</b>
      <div class="w-full flex align-items-center mt-2" style="height: 20px">
        <p-rating [(ngModel)]="rating.average" [cancel]="false" [readonly]="true" class="mr-2" />
        <small class="font-semibold">{{ rating.average || 0 }}</small>
      </div>
    </ng-template>
    <ng-template pTemplate="footer">
      <div class="flex gap-3 mt-1 justify-content-between align-items-baseline">
        <a (click)="toggleSidebar($event)"
           class="p-button-link underline"
           rounded="true"
           target="_blank">
          {{'components.item-card.button.view' | translate }}</a>
        @if(isMyCatalogView){
        <a *ngIf="item.itemAccessUrl"
           pButton
           (click)="openButton()"
           [href]="itemUrl"
           class="p-button"
           rounded="true"
           target="_blank"
           [label]="'components.item-card.button.open' | translate"></a>
        <a *ngIf="!item.itemAccessUrl"
           pButton
           class="p-button p-button-disabled"
           style="opacity: 0.4;"
           rounded="true"
           (click)="$event.preventDefault();"
           [label]="'components.item-card.button.open' | translate"></a>
        } @else {
        @if (userPermissionPending){
        <a pButton disabled class="p-button" rounded="true" target="_blank"
           [label]="'item_details.header.actions.pending' | translate "></a>
        } @else{
        <a pButton rounded [label]="'item_details.header.actions.get' | translate"
           (click)="toggleRequestForm($event)"></a>
        }
        }
      </div>
    </ng-template>
  </p-card>
</a>

<app-request-access-form [(isOpen)]="openRequestForm" [item]="item" [isRequest]="true" [requester]="item.ownerName"
                         (requestSent)="checkUserPermissions($event)"></app-request-access-form>
<app-details
  [(isOpen)]="sidebarVisible"
  [item]="item"
  (onAverageRateRefresh)="setAverageRate($event)"
  (onFavoriteChange)="setFavoriteChange($event)"
  (isOpenChange)="closeSidebar($event)">
</app-details>
