<div class="icon-upload-container">
  <p-fileUpload #fileUploader name="images[]" class="file-uploader" fileLimit="1" accept="image/*"
    chooseLabel="{{ 'catalog.form.fields.upload.label' | translate }}" (onSelect)="onFileSelected($event, fileUploader)"
    showUploadButton="false" showCancelButton="false" [disabled]="isDisabled">
    <ng-template pTemplate="file" let-file>
      <div class="p-fileupload-files ng-star-inserted">
        <div class="ng-star-inserted">
          <div class="p-fileupload-row ng-star-inserted flex-wrap">
            <div class="ng-star-inserted w-full text-center">
              <img [ngSrc]="renderImage(file)" alt="Selected Image" style="max-width: 100px; max-height: 100px;" width="100" height="100">
              <button style="float: right" pButton pRipple type="button" icon="pi pi-trash"
                class="p-button preview-action-button" (click)="deleteFile(file, fileUploader, $event)" [disabled]="isDisabled"></button>
            </div>
            <div class="p-fileupload-filename w-auto text-center"> {{ file.name }}</div>
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template pTemplate="empty">
      <div class="flex align-items-center justify-content-center flex-column">
        <i class="pi pi-cloud-upload border-2 border-circle p-5 text-5xl text-400 border-400"></i>
        <p class="mt-4 mb-0">{{ 'catalog.form.fields.upload.description' | translate }}</p>
        <p class="mt-4 mb-0">{{ 'catalog.form.fields.upload.or' | translate }}</p>
      </div>
    </ng-template>
  </p-fileUpload>
</div>
