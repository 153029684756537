import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, pipe, share, shareReplay } from 'rxjs';
import { environment } from '../../environments/environments';
import { ItemRequest, ItemRequestPost } from '../api/item-request';

/**
 * Service for managing item requests.
 */
@Injectable({
  providedIn: 'root',
})
export class ItemRequestService {
  baseRoute = environment.genuzApiEndpoint;

  requests$!: Observable<ItemRequest[]>;

  constructor(private httpClient: HttpClient) {}

  getMyItemRequests(): Observable<ItemRequest[]> {
    if (this.requests$)
      return this.requests$;

    this.requests$ = this.httpClient.get<ItemRequest[]>(`${this.baseRoute}MyItemRequest/GetByUserId`)
      .pipe(share());

    return this.requests$;
  }

  isRequestPending(params: any): Observable<boolean> {
    return this.httpClient.get<boolean>(`${this.baseRoute}MyItemRequest/is-pending`, {
      params: params,
    });
  }

  postMyItemRequest(body: ItemRequestPost): Observable<ItemRequest> {
    return this.httpClient.post<ItemRequest>(
      `${this.baseRoute}MyItemRequest`,
      body
    );
  }

  approveMyItemRequest(body: any): Observable<ItemRequest> {
    return this.httpClient.put<ItemRequest>(
      `${this.baseRoute}MyItemRequest/approve`,
      body
    );
  }

  cancelMyItemRequest(body: any): Observable<ItemRequest> {
    return this.httpClient.put<ItemRequest>(
      `${this.baseRoute}MyItemRequest/cancel`,
      body
    );
  }

  rejectMyItemRequest(body: any): Observable<ItemRequest> {
    return this.httpClient.put<ItemRequest>(
      `${this.baseRoute}MyItemRequest/reject`,
      body
    );
  }

  getMyItemRequest(id: number): Observable<ItemRequest> {
    return this.httpClient.get<ItemRequest>(
      `${this.baseRoute}MyItemRequest/${id}`
    );
  }
}
