export const resizeImage = (
  originalImage: any,
  imageURL: string,
  width: number = 1920,
  height: number = 1080
): Promise<any> => {
  return new Promise((resolve) => {
    if (originalImage.width < width && originalImage.height < height)
      return resolve(imageURL);

    const image = new Image();

    image.onload = function () {
      const canvas = document.createElement('canvas');

      canvas.width = width;
      canvas.height = height;

      const ctx = canvas.getContext('2d');

      if (ctx != null)
        ctx.drawImage(image, 0, 0, width, height);

      const data = canvas.toDataURL('image/jpeg', 1);

      resolve(data);
    };

    image.src = imageURL;
  });
}
