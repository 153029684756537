import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CardModule } from 'primeng/card';
import { ProgressBarModule } from 'primeng/progressbar';
import { ItemReviewAverageRate } from '../../../api/item-review';

type AverageRate = {
  total: number;
  ratingCount: Record<string, number>;
};

@Component({
  selector: 'average-rating-details',
  standalone: true,
  imports: [
    ProgressBarModule,
    CardModule,
    CommonModule,
    TranslateModule
  ],
  templateUrl: './average-rating-details.component.html',
  styleUrl: './average-rating-details.component.scss'
})
export class AverageRatingDetailsComponent implements OnInit, AfterViewInit {

  _averageRate!: ItemReviewAverageRate;

  @Input() set itemAverageRate(value: ItemReviewAverageRate) {
    this._averageRate = value;
    this.setScore();
  }

  get averageRate(): ItemReviewAverageRate {
    return this._averageRate;
  }

  constructor() {}

  ngOnInit() {
    this._averageRate = {
      total: 0,
      average: 0,
      ratingCount: { }
    };
  }

  ngAfterViewInit() {
    setTimeout(() => this.setScore(), 900);
  }

  setScore(): void {
    const scoring = { "1": 0, "2": 0, "3": 0, "4": 0, "5": 0 };

    if (this.ratings)
      this.ratings = { ...scoring, ...this.ratings };
  }

  get scales(): string[] {
    return Object.keys(this.ratings);
  }

  set ratings(value: Record<string, number>) {
    this.averageRate.ratingCount = value;
  }

  get ratings(): Record<string, number> {
    return this.averageRate?.ratingCount;
  }

  get total(): number {
    return this.averageRate.total;
  }

  get average(): number {
    if (!this.scales.length)
      return 0;

    return Number(
      this.scales
        .map(Number)
        .map(rate => (rate * this.ratings[rate] / this.total))
        .reduce((a, b) => a + b)
        .toFixed(1));
  }
}
