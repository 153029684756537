<div class="layout-topbar justify-content-between">
  <div class="flex">
    <div class="layout-topbar-logo justify-content-between">
      <a routerLink="">
        <img src="./assets/logo_light.svg" alt="logo">
      </a>
    </div>
  </div>
  <div class="flex align-items-center">
    <div class="mr-5">
      <a pButton routerLink="/my-catalog" severity="secondary" routerLinkActive="active-link" [rounded]="true"
         [text]="true" class="p-button mx-2">
        {{ 'top_menu.my_catalog_link' | translate }}
      </a>
      <a pButton routerLink="/store" severity="secondary" routerLinkActive="active-link" [rounded]="true"
         [text]="true"
         class="p-button mx-2">
        {{ 'top_menu.store_link' | translate }}
      </a>
      <a pButton routerLink="/feed" severity="secondary" routerLinkActive="active-link" [rounded]="true" [text]="true"
         class="p-button mx-2">
        {{ 'top_menu.feed_link' | translate }}
      </a>
    </div>
    <div>
      <button title="user-card" #topbarmenubutton class="p-link layout-topbar-menu-button layout-topbar-button"
              (click)="toggleUserCard(op, $event)">
        <i class="pi pi-ellipsis-v"></i>
      </button>

      <div #topbarmenu class="layout-topbar-menu align-items-center"
           [ngClass]="{'layout-topbar-menu-mobile-active': layoutService.state.profileSidebarVisible}">
        <div class="search-toolbar">
          <span class="p-input-icon-left">
            <i class="pi pi-search"></i>
            <input
                   pInputText
                   [pTooltip]="'search.tooltip' | translate"
                   tooltipPosition="bottom"
                   minlength="3"
                   maxlength="80"
                   [placeholder]="'components.topbar.search' | translate"
                   [value]="searchQuery"
                   [formControl]="searchControl"
                   (keyup.enter)="redirectToSearch($event)"
                   style="width: 20vw"
                   title="search-toolbar" />
          </span>
        </div>
        @if(user?.isAdmin){
        <button title="admin-button" class="p-link layout-topbar-admin-button"
                [ngClass]="{'active': !!this.layoutService.state.adminButtonActive}" (click)="adminButtonClick()">
          <i class="pi pi-cog"></i>
        </button>
        }

        <button [title]="'top_menu.user_documents' | translate"
                class="p-link ml-3 layout-topbar-admin-button"
                (click)="docButtonClick()">
          <i class="pi pi-question-circle" style="font-size: 2em;"></i>
        </button>

        <button class="p-link notification-icon layout-topbar-admin-button" [ngClass]="{'active': notificationCount > 0}"
          (click)="toggleNotificationsSidebar()">
          <i class="pi pi-bell" style="font-size: 2rem;"></i>
          <span *ngIf="notificationCount > 0" class="notification-badge" [ngClass]="getBadgeClass()">
            {{ getNotificationsCount() }}
          </span>
        </button>

        <button class="p-link layout-topbar-button user-avatar" (click)="toggleUserCard(op, $event)">
          @if (user?.photo) {
          <img title="user-photo" class="user-photo" [src]="user?.photo" />
          }
          @else {
          <i class="pi pi-user"></i>
          }
          <span>Profile</span>
        </button>

        <p-overlayPanel #op [appendTo]="'body'" [showCloseIcon]="true">

          <div class="topbar-menu-search w-full">
            <span class="p-input-icon-left">
              <i class="pi pi-search"></i>
              <input pInputText title="search"
                     [pTooltip]="'search.tooltip' | translate"
                     tooltipPosition="bottom"
                     minlength="3"
                     maxlength="80"
                     [value]="searchQuery"
                     [formControl]="searchControl"
                     (keyup.enter)="redirectToSearch($event)"
                     [placeholder]="'components.topbar.search' | translate" />
            </span>

            <p-divider class="w-full" />
          </div>

          <div class="flex flex-column align-items-center p-3">
            <div class="flex align-items-center gap-4">
              <img title="user-photo" class="border-circle" [src]="user?.photo" style="width: 80px" />
              <div>
                <span class="font-medium">{{ user?.name }}</span>
                <div class="text-sm text-color-secondary">{{ user?.mail }}</div>
              </div>
            </div>

            <p-divider class="w-full" />

            <div class="flex flex-column flex-start w-full p-2">
              <span class="flex font-light">
                {{ 'languages.label' | translate }}:
                <span class="font-bold ml-3 white-space-nowrap">{{ toFullLocale(user?.setting?.language) }}</span>
              </span>

              <p-button
                        class="mt-5 text-center"
                        [outlined]="true"
                        [rounded]="true"
                        (click)="showManageProfile()">{{ 'top_menu.manage_profile' | translate }}</p-button>
            </div>

            <p-divider class="w-full" />

            <div class="flex justify-content-between w-full">
              <a class="p-button p-button-link underline px-0" routerLink="/item-requests">{{ 'top_menu.item_requests' |
                translate}}</a>
              <p-button styleClass="underline px-0" [text]="true" (click)="logout()">{{ 'top_menu.logout' |
                translate}}</p-button>
            </div>
          </div>
        </p-overlayPanel>

        <p-sidebar
                   position="right"
                   [(visible)]="isNotificationSidebarVisible"
                   [styleClass]="'notifications'"
                   [appendTo]="'body'"
                   [showCloseIcon]="true"
                   (onHide)="setAllNotificationsToRead()">
          <ng-template pTemplate="header">
            <div class="notifications-header flex gap-4">
              {{ 'top_menu.notifications.title' | translate}}
            </div>
          </ng-template>

          <div class="flex flex-column notifications-content-size">
            <div class="notifications-scroll">
              @if (notifications.length <= 0) {
                 <div class="no-notifications">
                {{ 'top_menu.notifications.no_notifications' | translate}}
            </div>
            }
            @else {
            <div class="notification-card" *ngFor="let notification of notifications">
              <div class="notification-content" [ngClass]="getNotificationStatusClass(notification)">
                <div class="notification-header">
                  <span class="notification-title">{{ notification.relativeDate }}</span>
                </div>

                <div class="notification-close" (click)="deleteNotification(notification)">
                  <i class="pi pi-times"></i>
                </div>

                <div class="notification-body">
                  <span class="notification-text">{{ notification.message }}</span>
                </div>

                <div class="notification-link">
                  <a href="#" class="notification-link">{{ 'top_menu.notifications.view_more' | translate }}</a>
                </div>
              </div>
            </div>
            }
          </div>
      </div>

      <ng-template pTemplate="footer">
        <div class="flex justify-content-between w-full notifications-footer">
          <p-button
                    styleClass="underline px-0"
                    [text]="true"
                    (click)="deleteAllNotifications()">
            {{ 'top_menu.notifications.dismiss_all' | translate}}
          </p-button>
        </div>
      </ng-template>
      </p-sidebar>
    </div>
  </div>
</div>
</div>

<app-manage-profile [(isOpen)]="manageProfileBar" [(user)]="user"></app-manage-profile>